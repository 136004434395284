<template>
  <div class="ls-header com-min-width">
    <div class="logo"></div>
    <div class="title">
      零数开放许可链
      <div class="tm">TM</div>
    </div>
    <div class="tag">
      <div class="triangle"></div>
      服务网络工作台
    </div>
    <div v-if="!noMenu" class="menu-list">
      <div
        v-for="(item, i) in menuList"
        :key="i"
        class="menu-item"
        @mouseenter="changeMenu(item, 1)"
        @mouseleave="changeMenu(item, 0)"
        @click.stop="choseMenu(item)"
      >
        <el-badge v-if="item.isDot" :is-dot="item.isDot" class="item">
          <span
            class="ls-icons"
            :class="[
              { cur: menuName == item.name || hoverMenu == item.name },
              item.iconCss,
            ]"
          ></span>
        </el-badge>
        <span
          v-else
          class="ls-icons"
          :class="[
            { cur: menuName == item.name || hoverMenu == item.name },
            item.iconCss,
          ]"
        ></span>
        <span v-if="item.text" ref="textRef" class="text">{{ item.text }}</span>
      </div>
    </div>

    <MenuPopper :menuPopperItem="menuPopperItem" />

    <el-drawer title="通知中心" :visible.sync="isShowNotice">
      <ul
        class="infinite-list"
        v-infinite-scroll="loadData"
        :infinite-scroll-delay="500"
        infinite-scroll-disabled="disabled"
      >
        <NoticeBoxItem v-for="(item, i) in msgData" :key="i" :item="item" />
      </ul>
      <p v-if="loading" class="hint">加载中...</p>
      <p v-if="noMore" class="hint">{{ noMoreTxt }}</p>
    </el-drawer>
  </div>
</template>
<script>
import MenuPopper from "@/components/header/MenuPopper.vue";
import NoticeBoxItem from "@/components/header/NoticeBoxItem.vue";
import { msgList, msgRead, msgNewNum } from "@/api/interface/account.js";
import { mapGetters } from "vuex";

export default {
  components: {
    MenuPopper,
    NoticeBoxItem,
  },
  props: {},
  data() {
    return {
      textW: 0,
      menuList: [],
      menuName: "",
      hoverMenu: "",
      menuPopperList: {
        doc: {
          style: {
            right: "1.3rem",
          },
        },
        assets: {
          style: {
            right: "0.51rem",
          },
        },
        user: {
          style: {
            right: ".06rem",
          },
        },
      },
      menuPopperItem: {
        isShow: false,
      },
      isShowNotice: false,
      msgData: [],
      loading: false,
      pageNo: 1,
      pageSize: 5,
      noMore: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),
    disabled() {
      return this.loading || this.noMore;
    },
    noMoreTxt() {
      return this.noMore && this.pageNo == 1
        ? "暂无通知内容可查看"
        : "没有更多了";
    },
    noMenu() {
      return this.$route.name == "Agreement";
    },
    chargeModel() {
      return this.userInfo.shop && this.userInfo.shop.chargeModel; 
    }
  },
  watch: {
    userInfo: {
      handler(newVal) {
        this.setHeaderBar();
      },
      deep: true,
    },
  },
  methods: {
    changeMenu(item, type) {
      this.hoverMenu = type == 1 ? item.name : "";
    },
    choseMenu(item) {
      this.menuName = item.name;
      if (
        this.menuName == "doc" ||
        this.menuName == "assets" ||
        this.menuName == "user"
      ) {
        if (this.menuName != "user") {
          this.menuPopperList[this.menuName].style[
            "marginRight"
          ] = `${this.textW}px`;
        }
        this.menuPopperItem = Object.assign(
          {
            isShow: true,
            menuType: this.menuName,
          },
          this.menuPopperList[this.menuName]
        );
      } else {
        this.menuPopperItem = Object.assign({
          isShow: false,
        });
        if (item.isDot) {
          this.setMsgRead().then(() => {
            item.isDot = false;
          });
          this.noMore = false;
          this.pageNo = 1;
          this.msgData = [];
        }
        !this.noMore && this.getMsgList().finally(() => {
          this.loading = false;
        });
        this.isShowNotice = true;
      }
    },
    getMsgList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      return msgList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
        .then(({ records = [] }) => {
          if (records.length > 0) {
            this.msgData = this.msgData.concat(records);
            this.pageNo++;
          } else {
            this.noMore = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadData() {
      this.getMsgList();
    },
    setMsgRead() {
      return msgRead(0).then((data) => {
        return data;
      });
    },
    getMsgNewNum() {
      msgNewNum().then((data) => {
        this.menuList[0].isDot = data > 0;
      });
    },
    setHeaderBar() {
      if(this.chargeModel != 2) {
        this.menuList = [
          {
            name: "msg",
            iconCss: "icon-msg",
            isDot: false,
          },
          {
            name: "doc",
            iconCss: "icon-doc",
          },
          {
            name: "assets",
            iconCss: "icon-assets",
          },
          {
            name: "user",
            iconCss: "icon-user",
            text: this.userInfo.nickName,
          },
        ];
      } else {
        this.menuList = [
          {
            name: "msg",
            iconCss: "icon-msg",
            isDot: false,
          },
          {
            name: "doc",
            iconCss: "icon-doc",
          },
          {
            name: "user",
            iconCss: "icon-user",
            text: this.userInfo.nickName,
          },
        ];
      }

      this.$nextTick(() => {
        this.textW =
          this.$refs["textRef"] &&
          this.$refs["textRef"][0] &&
          this.$refs["textRef"][0].getBoundingClientRect().width;
      });
    },
  },
  created() {
    !this.noMenu && this.getMsgNewNum();
    this.setHeaderBar();
  },
  mounted() {
    document.addEventListener(
      "click",
      () => {
        this.menuName = "";
      },
      false
    );
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/el/_badge.scss";
.ls-header {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 48px;
  box-sizing: border-box;
  .logo {
    @extend %vertical-center;
    @extend %bg-contain;
    width: 150px;
    height: 42px;
    left: 48px;
    background-image: url("../../assets/images/common/logo.png");
  }
  .title {
    @extend %vertical-center;
    left: 210px;
    color: #0846ac;
    font-size: 18px;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
  }
  .tm {
    position: absolute;
    top: 0;
    right: -27px;
    font-size: 10px;
    color: #0846ac;
    height: 14px;
    line-height: 14px;
  }
  .tag {
    @extend %vertical-center;
    left: 375px;
    line-height: 20px;
    border-radius: 10px;
    background: #0846ac;
    color: #ffffff;
    font-size: 14px;
    padding: 4px 8px;
    .triangle {
      @extend %vertical-center;
      left: 0;
      width: 0;
      height: 0;
      border-width: 8px;
      border-style: solid;
      border-color: transparent #0846ac transparent transparent;
      left: -14px;
    }
  }

  .menu-list {
    @extend %vertical-center;
    right: 48px;
    display: flex;
    align-items: center;
    .menu-item {
      margin-right: 45px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .ls-icons {
        &.cur {
          ~ .text {
            color: #1d8db9;
          }
        }
      }
      .text {
        vertical-align: middle;
        font-size: 14px;
        line-height: 20px;
        margin-left: 7px;
        color: #3d3d3d;
      }
    }
  }
}
.infinite-list {
  overflow: auto;
  padding: 0;
}
.hint {
  text-align: center;
  margin-bottom: 36px;
}

:deep() .el-drawer {
  width: 514px !important;
  .el-drawer__header > :first-child {
    font-size: 18px;
    font-weight: 600;
    color: #3d3d3d;
    height: 25px;
    line-height: 25px;
    text-align: center;
  }
  .el-drawer__header {
    margin-bottom: 0.21rem;
  }
  .el-drawer__body {
    padding: 0 31px;
    .notice-box-item {
      border-bottom: 1px solid #d8d8d8;
      &:last-child {
        border: 0;
      }
    }
  }
}
</style>
