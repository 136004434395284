<template>
  <div
    v-show="isShow"
    class="menu-popper"
    :class="[menuType]"
    :style="menuPopperItem.style"
  >
    <div
      v-if="menuTitle[menuType] && menuTitle[menuType].title"
      class="menu-title"
    >
      {{ menuTitle[menuType].title }}
    </div>
    <div
      v-for="(item, i) in menuList[menuType]"
      :key="i"
      class="menu-link-list"
      :class="[item.class]"
      @click="choseMenu"
    >
      <div v-if="item.type == 'txtBtn'" class="menu-link-wrap">
        <span class="txt">{{ item.name }}</span>
        <span @click="gotoFuns(item)" class="btn">{{ item.btnName }}</span>
      </div>
      <div
        v-else-if="item.type == 'txtIcon'"
        class="menu-link-icon"
        :class="{ 'has-hover': item.hasHover }"
        @click="routeLink(item)"
      >
        <span class="icon-wrap"
          ><i class="ls-icons" :class="item.iconCss"></i
        ></span>
        <el-tooltip
          v-if="item.showAll"
          class="item"
          effect="dark"
          :content="item.name"
          placement="top"
        >
          <span class="txt">{{ item.name }}</span>
        </el-tooltip>
        <span v-else class="txt">{{ item.name }}</span>
        <span
          v-if="item.btnName && !item.btnHide"
          class="btn"
          @click.stop="gotoFuns(item)"
          >{{ item.btnName }}</span
        >
        <el-tag v-else-if="item.tagName" type="info" size="mini" class="tag">{{
          item.tagName
        }}</el-tag>
      </div>
      <div v-else class="menu-link-item" @click="routeLink(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { formatNumber } from "@/utils/common";
import { userLogout } from "@/api/interface/login.js";
import { gasInfo } from "@/api/interface/gas.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      isShow: false,
      menuTitle: {
        doc: { title: "介绍与文档" },
        assets: { title: "燃料" },
      },
      menuList: {},
    };
  },
  props: {
    menuPopperItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    menuPopperItem(val) {
      if (val.menuType) {
        this.isShow = true;
        this.setMenuPopper();
      } else {
        this.isShow = false;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "gasInfo"]),
    menuType() {
      return this.menuPopperItem.menuType;
    },
    enterpriseAuthStatus() {
      return (this.userInfo.shop && this.userInfo.shop.auditStatus) || 0;
    },
    authTxt() {
      let txt = "待审核";
      if (this.enterpriseAuthStatus == 0 || this.enterpriseAuthStatus == 3) {
        txt = "未认证";
      } else if (this.enterpriseAuthStatus == 2) {
        txt = "已认证";
      }
      return txt;
    },
    enterpriseAuthTxt() {
      return `企业${this.authTxt}`;
    },
    walletPublicKey() {
      return this.userInfo.shop && this.userInfo.shop.walletPublicKey;
    },
  },
  methods: {
    ...mapMutations(["setGasInfo"]),
    choseMenu() {
      this.isShow = false;
    },
    setMenuPopper() {
      if (this.menuType == "doc") {
        this.setDocPopper();
      } else if (this.menuType == "assets") {
        this.setAssetsPopper();
      } else if (this.menuType == "user") {
        this.setUserPopper();
      }
    },
    setDocPopper() {
      this.menuList[this.menuType] = [
        {
          name: "数字资产发行服务",
          routeName: "",
          link: "https://www.lingshu.net/solution/document",
        },
        { name: "数字资产保全服务", routeName: "" },
        { name: "存证服务", routeName: "" },
        { name: "电子签约", routeName: "" },
      ];
    },
    setAssetsPopper() {
      this.getGasInfo().then((data) => {
        this.setGasInfo(data);
        const menus = [
          {
            name: formatNumber(
              parseInt(data.usableTimes || 0) - parseInt(data.frozenTimes || 0)
            ),
            btnName: "充值",
            btnType: "recharge",
            type: "txtBtn",
            class: "txt-btn",
            routeName: "Recharge",
          },
          { name: "我的钱包", routeName: "FuelManage" },
          { name: "订单管理", routeName: "OrderMan" },
          { name: "什么是燃料？", routeName: "Question" },
        ];
        this.$set(this.menuList, this.menuType, menus);
      });
    },
    setUserPopper() {
      this.menuList[this.menuType] = [
        {
          iconCss: "icon-person",
          name: this.walletPublicKey,
          btnName: "复制",
          btnType: "copy",
          type: "txtIcon",
          noClick: true,
          showAll: true,
        },
        {
          iconCss: "icon-identify",
          name: this.enterpriseAuthTxt,
          btnName: "去认证",
          btnType: "identify",
          type: "txtIcon",
          routeName: "Account",
          noClick: true,
          btnHide: this.enterpriseAuthStatus == 2,
          hasHover: this.enterpriseAuthStatus == 2,
        },
        {
          iconCss: "icon-key",
          name: "密钥状态",
          tagName: "托管",
          type: "txtIcon",
          noClick: true,
        },
        {
          iconCss: "icon-safety",
          name: "安全中心",
          type: "txtIcon",
          routeName: "Account",
          hasHover: true,
        },
        {
          iconCss: "icon-exit",
          name: "退出登录",
          type: "txtIcon",
          func: this.loginOut,
          hasHover: true,
        },
      ];
    },
    loginOut() {
      this.$confirm("确认退出吗?", "提示", {
        type: "warning",
        center: true,
      }).then(() => {
        userLogout().then(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push("/login");
        });
      });
    },
    routeLink(item) {
      if (item.noClick) {
        return;
      }
      if (item.func) {
        item.func();
        return;
      }
      if (item.link) {
        window.open(item.link);
        return;
      }
      if (!item.routeName) {
        this.$message.info(this.$CommonMsg["building"]);
        return;
      }
      this.$router.push({ name: item.routeName });
    },
    gotoFuns(item) {
      const btnType = item.btnType;
      if (btnType == "recharge") {
        this.$router.push({ name: item.routeName });
      } else if (btnType == "identify") {
        this.$router.push({ name: item.routeName });
        this.isShow = false;
      } else if (btnType == "copy") {
        this.$copyText(item.name).then(
          (e) => {
            this.$message({
              message: "复制成功~",
              type: "success",
            });
          },
          (e) => {}
        );
      }
    },
    getGasInfo() {
      return gasInfo();
    },
  },
  mounted() {
    document.addEventListener(
      "click",
      () => {
        this.isShow = false;
      },
      false
    );
  },
};
</script>
<style lang="scss" scoped>
.menu-popper {
  position: absolute;
  width: 182px;
  border-radius: 8px;
  background-color: #012c57;
  box-sizing: border-box;
  border: 1px solid #1d8db9;
  padding: 16px 0;
  z-index: 9;
  top: 6.9vh;
  right: 20px;
  .menu-title {
    font-size: 14px;
    color: #fffefe;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    margin-bottom: 14px;
  }
  .menu-link-item {
    color: #ffffff;
    font-size: 14px;
    height: 37px;
    line-height: 37px;
    text-indent: 21px;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
      background-color: #1d8db9;
    }
  }
  .menu-link-wrap {
    height: 37px;
    line-height: 37px;
    padding: 0 6px 0 18px;
    margin-bottom: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .txt {
      font-size: 18px;
      color: #1d8db9;
      font-weight: 600;
      line-height: 21px;
      max-width: 100px;
      @extend %text-ellipsis;
    }
    .btn {
      border-radius: 68px;
      background: #1d8db9;
      color: #ffffff;
      font-size: 12px;
      line-height: 17px;
      padding: 6px 11px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  .menu-link-icon {
    position: relative;
    height: 37px;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 0.06rem;
    cursor: pointer;
    .icon-wrap {
      position: relative;
      width: 16px;
      height: 16px;
      margin-left: 12px;
      margin-right: 8px;
      .ls-icons {
        @extend %v-h-center;
      }
    }
    .txt {
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      word-break: break-word;
      max-width: 1.1rem;
      @include overFlowTextHidden(2);
    }
    .btn {
      @extend %vertical-center;
      right: 8px;
      border-radius: 68px;
      background: #1d8db9;
      color: #ffffff;
      font-size: 12px;
      line-height: 17px;
      padding: 6px 11px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .tag {
      @extend %vertical-center;
      right: 8px;
    }
    &.has-hover {
      &:hover {
        background-color: #1d8db9;
      }
    }
  }

  &.doc {
    .menu-title {
      text-align: center;
    }
  }
  &.assets {
    .menu-title {
      text-indent: 21px;
    }
  }
  &.user {
    width: 220px;
  }
}
</style>
