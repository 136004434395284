var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ls-header com-min-width"},[_c('div',{staticClass:"logo"}),_vm._m(0),_vm._m(1),(!_vm.noMenu)?_c('div',{staticClass:"menu-list"},_vm._l((_vm.menuList),function(item,i){return _c('div',{key:i,staticClass:"menu-item",on:{"mouseenter":function($event){return _vm.changeMenu(item, 1)},"mouseleave":function($event){return _vm.changeMenu(item, 0)},"click":function($event){$event.stopPropagation();return _vm.choseMenu(item)}}},[(item.isDot)?_c('el-badge',{staticClass:"item",attrs:{"is-dot":item.isDot}},[_c('span',{staticClass:"ls-icons",class:[
            { cur: _vm.menuName == item.name || _vm.hoverMenu == item.name },
            item.iconCss,
          ]})]):_c('span',{staticClass:"ls-icons",class:[
          { cur: _vm.menuName == item.name || _vm.hoverMenu == item.name },
          item.iconCss,
        ]}),(item.text)?_c('span',{ref:"textRef",refInFor:true,staticClass:"text"},[_vm._v(_vm._s(item.text))]):_vm._e()],1)}),0):_vm._e(),_c('MenuPopper',{attrs:{"menuPopperItem":_vm.menuPopperItem}}),_c('el-drawer',{attrs:{"title":"通知中心","visible":_vm.isShowNotice},on:{"update:visible":function($event){_vm.isShowNotice=$event}}},[_c('ul',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadData),expression:"loadData"}],staticClass:"infinite-list",attrs:{"infinite-scroll-delay":500,"infinite-scroll-disabled":"disabled"}},_vm._l((_vm.msgData),function(item,i){return _c('NoticeBoxItem',{key:i,attrs:{"item":item}})}),1),(_vm.loading)?_c('p',{staticClass:"hint"},[_vm._v("加载中...")]):_vm._e(),(_vm.noMore)?_c('p',{staticClass:"hint"},[_vm._v(_vm._s(_vm.noMoreTxt))]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" 零数开放许可链 "),_c('div',{staticClass:"tm"},[_vm._v("TM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag"},[_c('div',{staticClass:"triangle"}),_vm._v(" 服务网络工作台 ")])
}]

export { render, staticRenderFns }