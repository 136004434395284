<template>
  <div class="notice-box-item">
    <div ref="hideContentRef" class="hide-content">{{ item.content }}</div>
    <div class="title">{{ item.title }}</div>
    <div ref="contentRef" class="content" :class="{ 'is-expand': isExpand }">
      {{ item.content }}
    </div>
    <div class="time">{{ time }}</div>
    <div
      v-if="showArrow"
      @click="expandFunc"
      :class="arrowIcon"
      class="arrow-icon"
    ></div>
  </div>
</template>
<script>
import dayjs from "dayjs";

export default {
  data() {
    return {
      isExpand: false,
      showArrow: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    arrowIcon() {
      return this.isExpand ? "el-icon-arrow-up" : "el-icon-arrow-down";
    },
    time() {
      if (!this.item.createdTime) {
        return "";
      }
      return dayjs(this.item.createdTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    expandFunc() {
      this.isExpand = !this.isExpand;
    },
    setArrowStatus() {
      const hideContentH =
        this.$refs.hideContentRef.getBoundingClientRect().height;
      const contentH = this.$refs.contentRef.getBoundingClientRect().height;
      if (hideContentH <= contentH) {
        this.showArrow = false;
      } else {
        this.showArrow = true;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setArrowStatus();
    });
  },
};
</script>
<style lang="scss" scoped>
.notice-box-item {
  position: relative;
  padding: 29px 3px;
  box-sizing: border-box;
  .title {
    font-size: 16px;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
    color: #3d3d3d;
    @extend %text-ellipsis;
    max-width: 90%;
    margin-bottom: 10px;
  }
  .content {
    font-size: 14px;
    color: #3d3d3d;
    line-height: 20px;
    margin-bottom: 20px;
    @include overFlowTextHidden(3);
    &.is-expand {
      text-overflow: initial;
      -webkit-line-clamp: initial;
    }
  }
  .hide-content {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    top: 0;
    word-break: break-all;
    opacity: 0;
  }
  .time {
    font-size: 14px;
    line-height: 20px;
    color: #8f8f8f;
  }
  .arrow-icon {
    font-size: 18px;
    position: absolute;
    right: 0;
    bottom: 30px;
    cursor: pointer;
  }
}
</style>